<template>
    <div class="action-schedule">
        <div class="message">
            <div class="message-top">
                <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                <span v-html="introduction.title" />
            </div>
            <div class="message-content">
                <!-- <div class="desc" v-html="introduction.desc" /> -->
                <div class="desc" v-for="(d, idx) in desc" :key="idx" :class="{ 'li-dot': desc.length > 1 }">
                    {{ d }}
                </div>
                <div class="grey-box m-t-8">
                    <div class="user-profile">
                        <img :src="photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="age" />
                            <div class="region" v-html="content.region" />
                            <div class="job" v-html="content.job" />
                        </div>
                    </div>
                </div>
                <div class="grey-box m-t-8">
                    <ScheduleProgress :mode="'a'" :selected="2" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'

export default {
    name: 'ActionRescheduleFinA',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    methods: {},
    computed: {
        disabled() {
            return this.content.status === 1
        },
        introduction() {
            return {
                title: '잠시만 기다려주세요!',
                desc: `남성분에게 가능한 일정이 있는지 확인중입니다. 남성분 확인까지 시간이 소요될 수 있습니다. </br> 하루가 지나도 확정된 일정을 못 받았다면, 고객센터에 문의해주세요. (알림을 꺼놓은 회원분들이 일정 입력이 늦는 경우가 있습니다. 이 경우 매니저가 개별적으로 연락드려서 도와드릴 수 있습니다.) `,
            }
        },
        desc() {
            return [
                '남성분에게 가능한 일정이 있는지 확인중입니다. 남성분 확인까지 시간이 소요될 수 있습니다.',
                '하루가 지나도 확정된 일정을 못 받았다면, 고객센터에 문의해주세요. (알림을 꺼놓은 회원분들이 일정 입력이 늦는 경우가 있습니다. 이 경우 매니저가 개별적으로 연락드려서 도와드릴 수 있습니다.) ',
            ]
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
</style>
